import React from 'react';
import axios from 'axios';

// import Header from '../../components/Header';
import './style.scss';

export default function Orcamento(){
    let teste = ()=>{
        axios.get('/api/mensagem')
            .then(e=>{
                console.log(e)
            })
    }
    return(
        <>
            {/* <Header page="/casos" showLogo={true}/> */}
            <button onClick={teste}>Botao</button>
        </>
    );
}