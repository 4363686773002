import React, {useState, useEffect} from 'react'
import axios from 'axios'
import './style.scss'
import { useHistory } from 'react-router-dom'; 

export default function NovoSeguro() {
    const [disabled, setDisabled] = useState(true)
    const [data, setData] = useState([])
    const history = useHistory()

    const handleSubmit = (event) => {
        event.preventDefault()
        const target = event.target
        const {codigoOferta} = target
        if (codigoOferta && codigoOferta.value) {
            let value = codigoOferta.value
            value = value.toLowerCase()
            history.push(`/contratacao/${value}`)
            return
        }
    }

    const change = (event) => {
        const target = event.target
        const {value} = target
        if (value) {
            setDisabled(false)
            return
        }
        setDisabled(true)
    }

    const getOffer = async () => {
        try {
            const result = await axios.get('/api/offer')
            const { data: response } = result
            return response.data
        } catch(e) {
            console.error(`Error on call getOffer: ${e.message || e}`)
        }
    }

    useEffect(() => {
        const getData = async () => {
            const result = await getOffer()
            setData(result)
        }
        getData()
    }, [])

    return(
        <div className="novoSeguro">
            <div className="select-wrapper novoSeguro-select">
                <div onChange={getOffer}></div>
                <form onSubmit={handleSubmit}>
                    <label>Código de Oferta: </label>
                    <select name="codigoOferta" defaultValue={'null'} onChange={change}>
                        <option value="null" disabled={true}>Selecione</option>
                        {data.map(value => {
                            if(value.Name === 'ESPECIFICO-VIAGEM') value.Name = `Seguro Viagem`
                            return (
                            <option key={value.Name} value={value.CodigoOfertaConsultiva__c}>{value.Name}</option>
                            )
                        })}
                    </select>
                    <input type="submit" value="Entrar" className={disabled ? 'btnPortoSuccess disabled' : 'btnPortoSuccess'} disabled={disabled} />
                </form>
            </div>
        </div>
        
    );
}