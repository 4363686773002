//II-33 - INICIO
import React, {useState} from 'react';
import { FaSearch } from 'react-icons/fa';
import Text from '../../../components/LocalSearch/inputs/Text';
import DatePicker, {registerLocale} from "react-datepicker";
import { addDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css";
import './style.scss';

registerLocale("ptBR", ptBR); 

const handleError = (id, type=false) => {
    const errorMsg = 'Preencha o campo corretamente';
    if(type === false){
        document.getElementById(id).classList.remove('error');
        document.getElementById(id).dataset.error = '';    
    }else{
        document.getElementById(id).classList.add('error');
        document.getElementById(id).dataset.error = errorMsg;    
    }
}

const Search = ({ setSearch }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const onChange = (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    };

    const validateSubmit = (ev) => {
        const obj = {};
        ev.preventDefault();
        let valid = true;
        const ids = ['cpfContratante', 'numeroProposta'];

        if(!startDate || !endDate){
            valid = false;
            handleError('id_RangeDate', true);
        }else{
            // fnpvvep-45 - INICIO
            obj.dataInicio = startDate.toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
            obj.dataFinal = endDate.toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });            
            // fnpvvep-45 - FIM
        }

        ids.forEach(e => {
            const element = document.getElementById(`id_${e}`);
            handleError(`div-${e}`);
            if(element.value !== ''){
                if(element.value.length < element.attributes.minlength.value){
                    valid = false;
                    handleError(`div-${e}`, true);
                }else{
                    obj[e] = element.value;
                }
            }
        })

        if(valid){
            setSearch(obj);
        }    
    } 
    
    return (
        <form id='busca-local' onSubmit={validateSubmit}>            
            <div className='modulosBusca on'>
                <div className='module on'>
                    <div className='filtrar'><span>Filtrar por</span></div>
                    <div id='id_RangeDate' onClick={e => handleError('id_RangeDate')} className='input-field'>
                    <DatePicker
                        locale="ptBR"
                        showIcon
                        isClearable={true}
                        className="inputDatePicker"
                        placeholderText="Intervalo de Datas"
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        onChange={onChange}
                        minDate={addDays(new Date(), -120)} // FNPVVEP-142 - INICIO/FIM
                        maxDate={new Date()}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        showDisabledMonthNavigation
                    />
                    </div>                    
                    <Text id='id_cpfContratante' nome='CPF' type='cpf' param='cpfContratante' placeholder='999.999.999-99'/>                    
                    <Text id='id_numeroProposta' nome='Proposta' type='proposta' param='numeroProposta' placeholder='99-99999999'/>                    
                </div>
                <button type="submit" className="btn-primary"><FaSearch size={14} />Buscar </button>
            </div>
        </form>
    )
}
export default Search;
//II-33 - FIM