import React, { useState, useEffect } from 'react';
import axios from 'axios';

import './style.scss';

export default function OrcamentosAlterar(props){
    const urlData = props.location.state.data

    const [urlPortal, setUrlPortal] = useState('');
    const fadeIframe = ()=>{
        document.querySelector('iframe').classList.add('on');
        document.querySelector('.modalLoaderErro').classList.add('off'); //II-114 - INICIO/FIM
    }

    const getUrl = async ()=>{
        //II-114 - INICIO
        const codigoOferta = localStorage.getItem('codigoOferta').toUpperCase();
        await axios.get(`/api/geturlportal/${codigoOferta}`)
        //await axios.get(`/api/geturlportal/false`)
        //II-114 - FIM
        .then(e=>{
            setUrlPortal(e.data.data);
        })
    }

    useEffect(()=>{
        getUrl();
    }, [getUrl]);

    return(
        <div id="orcamentosAlterar">
            <section className='modalLoaderErro'>
                <div className='content'>
                    <div className="loader"></div>
                </div>
            </section>
            <iframe src={urlPortal + '?dados=' + urlData} frameBorder="0" onLoad={fadeIframe} title="Contratacao"></iframe>
        </div>
    );
}