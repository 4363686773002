/* Grid/Tabela de informações com ordenação e paginação */
/* Componente criado para atender as histórias do Portal: PLV-4021, PLV-4024, ... */ 

import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import './style.scss';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

export default function DataGrid (props){

    const getData = props.getData;
    const qtditens = props.qtditens;

    const [page, setPage] = useState(1);
    const [maxpages, setMax] = useState(1);
    const [data, setData] = useState([]);
    const [pageData, setPageData] = useState([]);
    const [order, setOrder] = useState(0); // 1 - ASC, 0 - SEM ORDEM, -1 - DESC
    const [orderColumn, setOrderColumn] = useState(null);
    const [erroIntegracao, setErro] = useState(null);

    const qtdpaginas = 5; // Quantas páginas aparecem na paginação (no rodapé).

    let updateOrder = (col) => {
        if(orderColumn === col) { 
            if(order === 0) { 
                setOrder(1); // ASC
            } else if (order === 1) { 
                setOrder(-1); //DESC
            } else {
                setOrder(0); 
                setOrderColumn(null);
            }
        } else {
            setOrderColumn(col);
            setOrder(1);
        }
    }

    // Apenas quando props mudar -- carrega os dados 
    useEffect(() => {
        getData().then(resposta=>{
            setData(resposta);
            setPageData(resposta.slice(0, qtditens));
            setMax(Math.ceil(resposta.length / qtditens));
            setPage(1);  
        }).catch(error => {
            setErro(error);
        }).finally(()=>{
            /* PLV-4254 - INICIO */ 
            if(document.querySelector('.pagina'))
                document.querySelector('.pagina').classList.add('on');
            if(document.querySelector('.modalLoaderErro'))
                document.querySelector('.modalLoaderErro').classList.add('off');
            /* PLV-4254 - FIM */
        })
    }, [props.search]); // PLV-4259

    // Quando página ou coluna/ordenação mudar -- atualiza dados da página
    useEffect(() => {
        if(Array.isArray(data)){
            var sortedData = [...data];
            if(orderColumn !== null){
                sortedData = sortedData.sort(function(a, b) {
                    if (a[orderColumn] > b[orderColumn]) {
                        return 1;
                    } else if (b[orderColumn] > a[orderColumn]) {
                        return -1;
                    } else 
                        return 0;
                });
                if(order === -1) sortedData.reverse(); // Decrescente
            }
            const ini = (page-1) * props.qtditens; 
            const fim = page * props.qtditens;
            setPageData(sortedData.slice(ini,fim)); 
        }
    }, [page,order,orderColumn,data,props])
    
    let cursor = (order === 1) ? <FaChevronUp size={14} className='icon'/> : 
                (order === -1) ? <FaChevronDown size={14} className='icon'/> : <span></span>;

    // Lista de colunas na tabela, passada pela prop "cols". 
    const colunas = props.cols.map((coluna,index) =>  
        (orderColumn === index) ?
            <th key={index} onClick={()=>updateOrder(index)}><span>{coluna}{cursor}</span></th> :
            <th key={index} onClick={()=>updateOrder(index)}><span>{coluna}</span></th>
    );

    //A página inicial que aparece no footer sempre será a qtdpaginas/2 menos a página atual.
    let pages;
    let pginicial =page-Math.round((qtdpaginas-1)/2);
    let pgvoltar = (page-1 >= 1) ?page-1 : 1;
    let pgavancar = (page+1 <=maxpages) ?page+1 :maxpages;
    if(pginicial < 1) pginicial = 1;

    // Lista de páginas que aparece no footer/paginação, partindo da página inicial até qtdpáginas
    for(let i = pginicial; i < pginicial+qtdpaginas && i <=maxpages; i++){
    pages = 
        <>{pages}
            <span className={(i === page) ? "pg pg-atual" : "pg"}
            onClick={()=>setPage(i)}>{i}
        </span></>;
    }

    // Cursores que aparecem no footer voltar/avançar, e a quantidade de registros sendo mostrada
    const cursorPrev = <span className="btnAnterior" onClick={()=>setPage(pgvoltar)}></span>;
    const cursorNext = <span className="btnProxima" onClick={()=>setPage(pgavancar)}></span>;
    const cursorInicio = <span className="btnPrimeira"onClick={()=>setPage(1)}></span>;
    const cursorFinal= <span className="btnUltima" onClick={()=>setPage(maxpages)}></span>;        
    const registros = 1 + (props.qtditens * (page-1)) + " - " +   
                (page !== maxpages ? props.qtditens*page : data.length) + " de " + data.length; 

   
    if(erroIntegracao){
        /* PLV-4259 - INICIO */ 
        return <Redirect to={{
            pathname: '/error',
            search: '?erro=' + erroIntegracao
        }} />
        /* PLV-4259 - FIM */
    } else { 
        return(
            <>
            <table className="grid-table">

                    <thead><tr className="grid-header">{colunas}</tr></thead>
                    <tbody>{
                        (pageData && pageData.length) ?
                    pageData.map((linha,idx) =>
                        <tr key={page+'_'+idx+'_'+order}>{props.cols.map((coluna,index) => <td key={page+'_'+index+'-'+idx+'_'+order}>{linha[index]}</td>)}</tr>
                        ) : <tr><td colSpan={props.cols.length}>Nenhum registro encontrado.</td></tr>   
                    }
                    </tbody> 
                    <tfoot><tr className="grid-footer"><td colSpan={props.cols.length}>
                    <div className="grid-pages">{cursorInicio}{cursorPrev}{pages}{cursorNext}{cursorFinal}</div>
                    <div className="grid-registros">{registros}</div> 
                    </td></tr></tfoot>
                
            </table>
            </>
        );
    }
}



