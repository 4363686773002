// PLV-4254 
import React, { createRef, useEffect } from 'react';
import VMasker from '../../../util/vMasker';

export default function Text(props){
    const input = createRef();
    const helper = createRef();
    const info = props.type;

    //PLV-5554 INICIO
    function inputHandler(masks, max, event) {
      // PLV-5554 - FIX02 - IN�CIO
      const c = event.target;
      const v = c.value.replace(/\D/g, '');
      let m;
      switch (v.length) {
        case 15:
          m = 0;
          break;
        case 16:
          m = 1;
          break;
        case 17:
          m = 2;
          break;
        default:
          m = 3;
      }
      // PLV-5554 - FIX02 - FIM
      VMasker(c).unMask();
      VMasker(c).maskPattern(masks[m]);
      c.value = VMasker.toPattern(v, masks[m]);
    }
    //PLV-5554 FIM

    useEffect(() => {
        if(info === 'data'){
            VMasker(input.current).maskPattern('99/99/9999');
        }
        if(info === 'text'){
            VMasker(input.current).maskText(); //PLV-4928 - INICIO/FIM 
            input.current.setAttribute('maxlength', '120');
            // PLV 4351 - Felipe - INICIO   
            input.current.setAttribute('minlength', '4');
        }
        if(info === 'cpf'){
            VMasker(input.current).maskPattern('999.999.999-99');
            input.current.setAttribute('minlength', '14');
        }
        if(info === 'apolice'){
            //PLV-5554 INICIO
            // PLV-5554 - FIX02 - INICIO
            const apoliceMask = ['9-9999-999999999-9', '99-9999-999999999-9', '999-9999-999999999-9', '9999-9999-999999999-9'];
            const apolice = document.querySelector('#numeroApolice0');
            // PLV-5554 - FIX02 - FIM
           
            if(apolice.value.length == 19){ // PLV - 5554
                // if(apolice.value.charAt(0) == '0'){
                    // apolice.value = apolice.value.slice(1);
                    // VMasker(apolice).maskPattern(apoliceMask[0]);
                // }else{
                    VMasker(apolice).maskPattern(apoliceMask[1]);
                // }
            }else if(apolice.value.length == 20){
                VMasker(apolice).maskPattern(apoliceMask[2]);
            }
            else if(apolice.value.length == 21){
                VMasker(apolice).maskPattern(apoliceMask[3]);
            }
            else{
                VMasker(apolice).maskPattern(apoliceMask[0]);
            }
            apolice.addEventListener('input', inputHandler.bind(undefined, apoliceMask, 21), false);

            input.current.setAttribute('minlength', '18');
            //PLV-5554 FIM
        }
        //PLV-4928 - IN�CIO 
        if(info === 'proposta'){
            VMasker(input.current).maskPattern('99-99999999');
            input.current.setAttribute('minlength', '11');
        }
        if(info === 'orcamento'){
            VMasker(input.current).maskPattern('SSS-999999999'); //PLV-4928 - FIX3 - INICIO/FIM
            input.current.setAttribute('minlength', '12');
        }
        //PLV-4928 - FIM 
    }, [input, info, helper]);

    return(
        <div className="input-field" id={`div-${props.param}`} key={props.id} error={props.error}>
            <input type="text" ref={input} placeholder={props.placeholder} id={props.id} name={props.param}/>
            <label htmlFor={props.id}>{props.nome}</label>
        </div>
    );
}