// FNPVVEP-23 - INICIO
import { format, subDays } from 'date-fns';
import React, { useState } from 'react';
import axios from 'axios'; // FNPVVEP-24 - INICIO/FIM
import ReactDatePicker from 'react-datepicker';
import fnc from '../../../util/functions'; // FNPVVEP-24 - INICIO/FIM
import { MSGS } from '../msgs'; // FNPVVEP-24 - FIX02 - INICIO/FIM

import MultiValueCheckbox from '../../../components/MultiValueCheckbox';

import './style.scss';

const tipo = ['Novo', 'Endosso', 'Renovação', 'Cancelamento'];
const status = ['Emitido', 'Cancelado', 'Alterado'];

const MAX_OFFSET_DAYS = 120;

export default function Form({handleShowAlert, handleModalClose, handleSearch, handleTokenModal}) { // FNPVVEP-24 - INICIO/FIM | FNPVVEP-24 - FIX - INICIO/FIM | // FNPVVEP-86 - INICIO/FIM | FNPVVEP-86 - FIX01 - INICIO/FIM
  const today = new Date();
  const startDate = new Date(
    new Date().setDate(new Date().getDate() - MAX_OFFSET_DAYS)
  );
  const currentYear = today.getFullYear();
  const years = Array.from({ length: 4 }, (_, index) => currentYear - index);

  const [filter, setFilter] = useState({
    dateType: 'year',
    year: currentYear,
    startDate,
    endDate: today,
    tipo,
    status,
  });

  const handleDateTypeChange = (event) => {
    event.persist();
    setFilter((currentFilter) => ({
      ...currentFilter,
      dateType: event.target.value,
    }));
  };

  const handleYearChange = (event) => {
    event.persist();
    setFilter((currentFilter) => ({
      ...currentFilter,
      year: event.target.value,
    }));
  };

  const handleDateChange = (field, date) => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      [field]: date,
    }));
  };

  const handleCheckChange = (event) => {
    const { value, checked, name } = event.target;
    if (checked) {
      setFilter((currentFilter) => ({
        ...currentFilter,
        [name]: [...currentFilter[name], value],
      }));
    } else {
      setFilter((currentFilter) => ({
        ...currentFilter,
        [name]: currentFilter[name].filter((item) => item !== value),
      }));
    }
  };

  const getDateInterval = () => {
    if (filter.dateType === 'year') {
      const firstDayOfTheYear = new Date(filter.year, 0, 1);
      const lastDayOfTheYear = new Date(filter.year, 11, 31, 23, 59, 59, 999);
      return [firstDayOfTheYear, lastDayOfTheYear];
    }
    return [filter.startDate, filter.endDate];
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleModalClose();
    handleShowAlert({type: 'infoMsg', msg: MSGS.formInfoMsg});
    handleSearch(3000); // FNPVVEP-24 - FIX04 - INICIO/FIM 

    const [startDate, endDate] = getDateInterval();
    const output = {
      startDate,
      endDate,
      tipo: filter.tipo,
      status: filter.status,
    };

    // FNPVVEP-24 - INICIO
    const now = new Date();
    axios.post('/api/handlecontractreport', 
      { 
        name: `${now.toISOString()}_${localStorage.getItem('susepCorretor')}`,
        susep: localStorage.getItem('susepCorretor'),
        isyear: filter.dateType === 'year' ? true : false,
        year: filter.year,
        startDate,
        endDate,
        tipo: fnc.removeAccents(filter.tipo.join(';')),
        status: filter.status.join(';')        
      } 
    ).then(response => {
      handleSearch(0); // FNPVVEP-24 - FIX02 - INICIO/FIM
      // FNPVVEP-86 - INICIO | FNPVVEP-86 - FIX01 - INICIO
      const {type, msg, token} = response.data;
      handleShowAlert({type, msg});
      if(type === 'successMsg') setTimeout(() => handleTokenModal({show: true, token}), 2000); //FNPVVEP-86 - FIX02 - INICIO/FIM
      // FNPVVEP-86 - FIM | FNPVVEP-86 - FIX01 - FIM
    })

    console.info('output', output);
    // FNPVVEP-24 - FIM
  };

  const errors = {
    tipo: filter.tipo.length === 0,
    status: filter.status.length === 0,
  };

  const hasError = Object.values(errors).some((value) => value);

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-input-container">
        <div className="date-type-container">
          <label>Filtrar data por:</label>
          <label>
            <input
              type="radio"
              name="option"
              value="year"
              checked={filter.dateType === 'year'}
              onChange={handleDateTypeChange}
            />
            Ano
          </label>
          <label>
            <input
              type="radio"
              name="option"
              value="interval"
              checked={filter.dateType === 'interval'}
              onChange={handleDateTypeChange}
            />
            Intervalo de datas
          </label>
        </div>
        <div className="date-container">
          {filter.dateType === 'year' ? (
            <div className="select-wrapper">
              <label htmlFor="year">Ano</label>
              <select
                name="year"
                id="year"
                value={filter.year}
                onChange={handleYearChange}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div className="datepicker-container">
              <div className="select-container">
                <div className="select-wrapper">
                  <label>Intervalo</label>
                  <ReactDatePicker
                    locale="ptBR"
                    value={format(filter.startDate, 'dd/MM/yyyy')}
                    showIcon
                    placeholderText="Data inicial"
                    dateFormat="dd/MM/yyyy"
                    selected={filter.startDate}
                    onChange={(date) => handleDateChange('startDate', date)}
                    minDate={subDays(filter.endDate, MAX_OFFSET_DAYS)}
                    maxDate={today}
                    className="datepicker-wrapper"
                  />
                </div>
                <div className="select-wrapper">
                  <ReactDatePicker
                    locale="ptBR"
                    value={format(filter.endDate, 'dd/MM/yyyy')}
                    showIcon
                    placeholderText="Data final"
                    dateFormat="dd/MM/yyyy"
                    selected={filter.endDate}
                    onChange={(date) => handleDateChange('endDate', date)}
                    minDate={filter.startDate}
                    maxDate={today}
                    className="datepicker-wrapper"
                  />
                </div>
              </div>
              <span>
                O intervalo não pode ultrapassar {MAX_OFFSET_DAYS} dias
              </span>
            </div>
          )}
        </div>
        <div className="checkbox-container">
          <div>
            <MultiValueCheckbox
              label="Tipo"
              name="tipo"
              items={tipo}
              onChange={handleCheckChange}
              selectedValues={filter.tipo}
            />
            {!!errors.tipo && (
              <p className="error-message">Tipo não pode ser vazio</p>
            )}
          </div>
          <div>
            <MultiValueCheckbox
              label="Status"
              name="status"
              items={status}
              onChange={handleCheckChange}
              selectedValues={filter.status}
            />
            {!!errors.status && (
              <p className="error-message">Status não pode ser vazio</p>
            )}
          </div>
        </div>
        <button disabled={hasError}>Gerar Relatório</button>
      </div>
    </form>
  );
}
// FNPVVEP-23 - FIM
