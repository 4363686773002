// PLV-4259
import React from 'react';
import crypto from 'crypto';
import VMasker from '../../../../util/vMasker'; //FNPVVEP-33-FIX-2 INICIO-FIM

export default function Info(props){

    const tratativeDetails = (object) => {
        if(typeof object === 'object'){
            if(Array.isArray(object)){
                let chaveLista = crypto.randomBytes(4).toString('HEX');
                return (
                    <ul key={chaveLista}>
                        {   
                            object.map(e=>{
                                let chaveListItem = crypto.randomBytes(4).toString('HEX');
                                return (
                                    <li key={chaveListItem} className="list-item">
                                        {
                                            Object.keys(props.field.value.fields).map((campos,i)=>{
                                                //FNPVVEP-33 INICIO
                                                //let campo = props.field.value.labels[i];
                                                let valor = e[props.field.value.fields[i]] !== 'null' && e[props.field.value.fields[i]] !== null?
                                                    e[props.field.value.fields[i]] :
                                                    '';
                                                //FNPVVEP-33 FIM
                                                let chaveListPropriedade = crypto.randomBytes(4).toString('HEX');
                                                return (
                                                    <React.Fragment key={chaveListPropriedade}>
                                                        {applyMask(valor)}
                                                        <br />
                                                    </React.Fragment>
                                                );
                                            })
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>    
                )    
            }else{
                let chaveLista = crypto.randomBytes(4).toString('HEX');
                return <ul key={chaveLista}>
                    {
                        Object.keys(props.field.value.fields).map((e,i)=>{
                            let chaveListItem = crypto.randomBytes(4).toString('HEX');

                            let campo = props.field.value.labels[i];
                            let valor = ( object[props.field.value.fields[i]] !== 'null' && object[props.field.value.fields[i]] !== 'nullnull') ?
                                object[props.field.value.fields[i]] :
                                '';

                            return (
                                <li key={chaveListItem}>
                                    <b>{ campo + ':'}</b>
                                    <span>{ applyMask(valor) }</span>
                                </li>
                            )
                        })
                    }
                </ul>  
            }
        }else{
            let chave = crypto.randomBytes(4).toString('HEX');
            return <span key={chave}>{applyMask(object)}</span>;
        }
    }
    //FNPVVEP-33 INICIO
    const applyMask = (field) => {
        const numberValidate = /[0-9]/;
        const cepValidate = /(\d{5})(\d{3})/
        const cpfValidate = /(\d{3})(\d{3})(\d{3})(\d{2})/
        //FNPVVEP-33-FIX-2 INICIO
        if(typeof field === 'number' && numberValidate.test(field) === true){
            if(props.field.label === 'Garantias' || props.field.label === 'Prêmio Total'){
                field = (props.field.label === 'Garantias') ? field + '.00' : field ;
                field = 'R$ ' + VMasker.toMoney(field)                
            }else if(props.field.label === 'Remuneração'){
                field += ' %'
            }
        }
        //FNPVVEP-33-FIX-2 FIM
        if(props.field.label === 'Número da proposta'){
            field = '18-' + String(field).padStart(8,"0")
        }
        if(cpfValidate.test(field) === true) {
            field = field.replace(cpfValidate, '$1.$2.$3-$4')
        }
        if(cepValidate.test(field) === true && props.field.label === 'Endereço'){
            field = field.replace(cepValidate, '$1-$2')
        }
        return (
            field
        )
    }
    //FNPVVEP-33 FIM

    const render = () => {
        if(props.value !== null){
            let chaveInfo = crypto.randomBytes(4).toString('HEX');
            return (
                <div key={chaveInfo} className={"info" + (typeof props.value === 'object'? ' list': '')} >
                    <b>{props.field.label}</b>
                    {tratativeDetails(props.value)}
                </div>
            )
        }else{
            return '';
        }
    }

    return render();
}