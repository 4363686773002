import React, { useState } from 'react'; //PLV-4896 - INÍCIO 
import { Link, Redirect } from 'react-router-dom'; //PLV-4896- FIM PLV-5441 - INICIO/FIM
import axios from 'axios';
import fnc from '../../util/functions'; //PLV-5817 - INÍCIO/FIM
import { FaCheck, FaDownload, FaFileAlt, FaPlusCircle, FaSearch, FaStar, FaTimes, FaExclamationCircle, FaLink, FaMinus, FaExternalLinkAlt } from 'react-icons/fa'; // PLV-5436 - INÍCIO/FIM // FNPVVEP-97 - INICIO/FIM
import { FiRepeat } from 'react-icons/fi';
import DataGrid from '../../components/DataGrid';
import Modal from './Modal'; // PLV-5436 - INÍCIO/FIM
/// PLV-4265 Importando o componente TOOLTIO - Felipe Pedro
/// INICIO
import { Tooltip } from '../../components/Tooltip'
/// FIM 

import LocalSearch from '../../components/LocalSearch'; //PLV-4928 - INÍCIO/FIM 

import './style.scss';
// PLV-5436 - INÍCIO
import Stepper from './Stepper';
// MGRC-705 - INICIO
import getDocument, { openPdfInNewTab } from '../../util/pdf'; // MGRC-649 - INICIO/FIM
import ModalPdfError from '../../components/Modal';
import Spinner from '../../components/Spinner';
// MGRC-705 - FIM

const parseStatus = ({
  statusCriacao,
  statusAnalise,
  statusDispContratacao,
  statusContratada,
  statusRecusada,
  faseStatusAnalise,
  motivoRecusa, // II-49 - INICIO/FIM
}) => {

  const status = [];
  const subStatus = {};

  const addStatus = (label, datetime, icon) => {
    const date = datetime ? fnc.parseDate(datetime) : null; //PLV-5817 - INÍCIO/FIM
    status.push({ label, date, icon });
  };
  
  //PLV-5817 - INÍCIO
  const addSubStatus = (paramFaseStatusAnalise, icon) => {
    if (!paramFaseStatusAnalise) return;
    const separator = ' - ';
    const indexMomento = 0;
    const indexStatus = 1;
    const indexData = 2;
    const grupoSubscricao = 'Subscrição';
    const grupoAnalise = 'Análise interna';
    const statusLiberado = 'Liberado';
    const statusEmAprovacao = 'Em aprovação';
    const statusPendente = 'Pendente';

    //const newCriticas = [...paramFaseStatusAnalise, ...paramFaseStatusAnaliseNew];
    const newCriticas = paramFaseStatusAnalise.map(e => {
      const newSplitValue = e.split(separator);
      return {grupo: newSplitValue[indexMomento], status: newSplitValue[indexStatus], data: newSplitValue[indexData]};
    }).reduce((uniqueArray, currentCritica) => { // FNPVVEP-106 - INICIO
      const existingCriticaIndex = uniqueArray.findIndex(
        (critica) =>
          critica.grupo === currentCritica.grupo &&
          critica.status === currentCritica.status
      );
    
      if (existingCriticaIndex >= 0) {
        const existingCritica = uniqueArray[existingCriticaIndex];
        const existingCriticaDate = new Date(existingCritica.data);
        const currentCriticaDate = new Date(currentCritica.data);
    
        if (currentCriticaDate > existingCriticaDate) {
          uniqueArray[existingCriticaIndex] = currentCritica;
        }
      } else {
        uniqueArray.push(currentCritica);
      }
    
      return uniqueArray;
    }, []); // FNPVVEP-106 - FIM

    console.info('Críticas Retornadas', newCriticas);

    const sortedObjects = [...newCriticas];

    //REGRA #1 - Remover a primeira ::Analise Interna - Liberado:: se tiver mais que um 
    /*if(sortedObjects.filter(e => e.grupo === grupoAnalise && e.status === statusLiberado).length > 1){
      const indexToRemove = sortedObjects.findIndex((e) => e.grupo === grupoAnalise && e.status === statusLiberado);
      sortedObjects.splice(indexToRemove, 1);
    }*/

    //REGRA #2 - Remover o primeiro status de ::Subscrição - Pendente:: se tiver status de Aprovação 
    if(sortedObjects.filter(e => e.grupo === grupoSubscricao && e.status === statusEmAprovacao || 
      e.grupo === grupoSubscricao && e.status === statusPendente).length > 0){
        const indexToRemove = sortedObjects.findIndex((e) => e.grupo === grupoSubscricao && e.status === statusPendente);
        const indexToCompare = sortedObjects.findIndex((e) => e.grupo === grupoSubscricao && e.status === statusEmAprovacao);
        if(indexToRemove < indexToCompare) sortedObjects.splice(indexToRemove, 1);
    }

    console.info('Críticas Atualizadas', sortedObjects);

    const copiedSortedObjectsNoDate = sortedObjects.map(e => {
      return {grupo: e.grupo, status: e.status}
    });

    const objSubsStatus = {sortedObjects, copiedSortedObjectsNoDate, icon, motivoRecusa} // II-49 - INICIO/FIM
    Object.assign(subStatus, objSubsStatus);    
  };
  //PLV-5817 - FIM

  addStatus('Proposta transmitida para análise', statusCriacao, FaPlusCircle); //PLV-5817 - INÍCIO/FIM
  addStatus('Em análise de emissão', statusAnalise, FaSearch); //PLV-5817 - INÍCIO/FIM
  if (!!statusAnalise) {
    addSubStatus(faseStatusAnalise, FaExclamationCircle); //PLV-5817 - INÍCIO/FIM
  }
  addStatus('Disponível para contratação', statusDispContratacao, FaFileAlt); //PLV-5817 - INÍCIO/FIM
  if (!statusContratada && !statusRecusada) {
    addStatus('Em processamento', '', FaStar);
  } else {
    if (!!statusContratada) {
      addStatus('Proposta contratada', statusContratada, FaCheck); //PLV-5817 - INÍCIO/FIM
    }
    if (!!statusRecusada) {
      addStatus('Proposta recusada', statusRecusada, FaTimes); //PLV-5817 - INÍCIO/FIM
    }
  }

  return [status, subStatus];
};
// PLV-5436 - FIM

export default function  Proposta(){
    const colunas = ['Proposta', 'Cliente', 'Status', 'Produto', 'Voucher/Proposta', 'Nova oferta', 'Apólice', 'Questionário Saúde']; //PLV-4896 - INÍCIO/FIM PLV-5441 - INICIO/FIM FNPVVEP-22 - INICIO/FIM // FNPVVEP-97 - INICIO/FIM
    const qtditens = 10;
    const [erroIntegracao, setErro] = useState(null); //PLV-4896 - INÍCIO/FIM
    // PLV-5436 - INÍCIO
    const [status, setStatus] = useState(null);
    const [subStatus, setSubStatus] = useState(null);
    // PLV-5436 - FIM
    
    const [searchParams, setSearchParams] = useState({}); //PLV-4928 - INÍCIO/FIM PLV-5441 - INICIO/FIM

    // MGRC-705 - INICIO
    const [showModalDownloadError, setShowModalDownloadError] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const handleDownloadPdf = async (token) => {
      setShowSpinner(true);
      const pdfDocument = await getDocument(token);
      setShowSpinner(false);
      if (!!pdfDocument) {
          openPdfInNewTab(pdfDocument);
      }
      else {
          setShowModalDownloadError(true);
      }
  }
  // MGRC-705 - FIM
  // FNPVVEP-97 - INICIO
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [samplemedResponse, setSamplemedResponse] = useState(null);
  // FNPVVEP-97 - FIM

  // FNPVVEP-22 - INICIO
  const getDownloadUrl = (proposta) =>
    !!proposta.apolicePdfDownloadUrl
      ? proposta.apolicePdfDownloadUrl
      : !!proposta.endossoPdfDownloadUrl
      ? proposta.endossoPdfDownloadUrl
      : null;
  // FNPVVEP-22 - FIM

  // FNPVVEP-97 - INICIO
  const reenviarLink = (linkExpirado, idProponente) => {
    setSamplemedResponse(null);
    axios
      .post('api/link-samplemed', { idProponente })
      .then(({ data }) => {
        // FNPVVEP-97 - INICIO - FIX02
        setSamplemedResponse(
          <p>
            {data.status === 'success'
              ? 'Link enviado com sucesso!'
              : 'Erro ao requisitar link.'}
          </p>
        );
        // FNPVVEP-97 - FIM - FIX02
      })
      .catch((error) => {
        setSamplemedResponse(<p>Erro ao requisitar link.</p>); // FNPVVEP-97 - INICIO/FIM - FIX01
        console.error(error);
      });
    setIsLinkExpired(linkExpirado);
    setShowLinkModal(true);
  }
  
  const closeLinkModal = () => {
    setShowLinkModal(false);
  }
  
  const linkModalContent = isLinkExpired ? (
    <>
      <p>Infelizmente o link está expirado, favor preencher o formulário.</p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
        <a
          href="https://na2.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=a39244fe-2c4e-42b9-bcfb-ba363c5db7d0&env=na2&acct=d6433a73-0fbb-49cd-85f1-7b8a6413a551&v=2"
          target="_blank"
          rel="noopener noreferrer"
          >
          <button
            className="btn btn-primary"
            onClick={closeLinkModal}
            style={{ padding: '0.5rem' }}
            >
            <FaExternalLinkAlt size={10} /> Preencher Formulário
          </button>
        </a>
      </div>
    </>
  ) : samplemedResponse === null ? (
    <p>Reenviando link...</p> // FNPVVEP-97 - INICIO/FIM - FIX01
  ) : (
    samplemedResponse
  );
  // FNPVVEP-97 - FIM

    const getDataTable = () => {
        document.querySelector('.modalLoaderErro').classList.remove('off'); //PLV-4896 - FIM 
        document.querySelector('.pagina').classList.remove('on'); //PLV-4928 - INÍCIO/FIM          
        return axios.get('/api/proposal', { 
            params: {
                canal: 'Corretor',
                idCanal: localStorage.getItem('susepCorretor'),
                codigoOferta: localStorage.getItem('codigoOferta'), //II-124 - INICIO/FIM
                ...searchParams // PLV-4928 - INÍCIO/FIM  
            }
            }).then(r=>{
              //console.log('proposal', r);
              if(r.data && r.data.data) {
                // PLV-5441 - INICIO
                const dados = r.data.data.map((l) => {
                    console.log(l);
                    const [currentStatus, currentSubStatus] = parseStatus(l); // PLV-5436 - INÍCIO/FIM
                    const pdfDownloadUrl = getDownloadUrl(l);
                    // II-56 - INICIO
                    //const showReaproveitar = l?.segment.toLowerCase()  === 'individual' && l?.code === 'VI-PESSOAS'; // PLV-5528 - INICIO/FIM
                    const showReaproveitar = l?.segment.toLowerCase()  === 'individual';
                    // II-56 - FIM
                    const propostaNome = `${l.source}-${(l.proposal + "").padStart(8, "0")}`;
                    // PLV-5441 - FIM
                        return [
                          propostaNome, //PLV-4896 - INÍCIO/FIM PLV-5441 - INICIO/FIM
                          l.client,
                          /// PLV-4265 Condição para ver o status da proposta, caso não seja
                          /// "CONTRATADA", é chamado um componente TOOLTIP e apresenta o motivo - Felipe Pedro
                          /// INICIO
                            // PLV-5436 - INÍCIO
                            <div className="description-icon">
                              {l.status}{' '}
                              <FaPlusCircle
                                onClick={() => {
                                  setStatus(currentStatus);
                                  setSubStatus(currentSubStatus);
                                }}
                              />
                            </div>,
                            // PLV-5436 - FIM
                          /// FIM
                          l.product,
                          //PLV-4896 - INÍCIO
                          l.status !== 'Em criação' &&
                          l.status !== 'Recusada' ? (
                            l.product &&
                            l.product.toLowerCase().includes('individual') ? ( //PLV-4896 - FIX3 - INICIO/FIM
                              <a
                                onClick={() =>
                                  downloadPDF(
                                    `${l.source}-${(l.proposal + '').padStart(
                                      8,
                                      '0'
                                    )}`
                                  )
                                }
                              >
                                <button className="btn">
                                  <FaDownload size={10} /> Proposta
                                </button>
                              </a>
                            ) : l.voucher != null ? (
                              // MGRC-649 - INICIO
                              <button
                                className="btn"
                                onClick={() => handleDownloadPdf(l.voucher)} // MGRC-705 - INICIO/FIM
                              >
                                <FaDownload size={10} /> Voucher
                              </button>
                              // MGRC-649 - FIM
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          ),
                          // PLV-5441 - INICIO
                          showReaproveitar && (
                            <Link to={`/contratacao?proposta=${propostaNome}`}>
                              <button className="btn">
                                <FiRepeat size={10} /> Aproveitar dados em nova
                                oferta
                              </button>
                            </Link>
                            // PLV-5441 - FIM
                          ),
                          // FNPVVEP-22 - INICIO
                          l.status === 'Contratada' && !!pdfDownloadUrl && (
                            <button
                              className="btn"
                              onClick={() => handleDownloadPdf(pdfDownloadUrl)}
                            >
                              <FaDownload size={10} /> Apólice
                            </button>
                          ),
                          // FNPVVEP-22 - FIM
                          // FNPVVEP-97 - INICIO
                          l.status === 'Em análise' && !!l.idProponente ? (
                            <button className="btn" onClick={() => reenviarLink(l.linkExpirado, l.idProponente)}>
                              <FaLink size={10} /> Reenviar Link
                            </button>
                          ) : (
                            <FaMinus size={10} />
                          ),
                          // FNPVVEP-97 - FIM
                        ];
                                 //PLV-4896 - FIM  
                                
                    });
                    return dados;
                }
                return [];
            });
    };

    //PLV-4928 - INÍCIO 
    const search = {
        nome: {
            label: 'Nome',
            module: [
                { param: 'nomeSegurado', label: 'Nome', type: 'text' },
            ],
        },
        apolice: {
            label: 'Proposta',
            module: [
                { param: 'numeroProposta', label: 'Proposta', type: 'proposta', placeholder:'99-99999999' }
            ]
        },
        cpf: {
            label: 'CPF',
            module: [
                { param: 'cpfContratante', label: 'CPF', type: 'cpf', placeholder:'999.999.999-99' }
            ]
        },
        data: {
            label: 'Data',
            module: [
                { param: 'dataProposta', label: 'Data', type: 'data', placeholder:'DD/MM/YYYY' }
            ]
        },
    }
    //PLV-4928 - FIM 
    //PLV-4896 - INÍCIO 
    const downloadPDF = (proposta) => {
        document.querySelector('.modalLoaderErro').classList.remove('off');

        axios.get('/api/proposalpdf', {
            params: {
                proposta: proposta,
                canal: 'Corretor',
                idCanal: localStorage.getItem('susepCorretor'),
            } 
           
        }).then(r=>{
            var byteCharacters = atob(r.data.data);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var file = new Blob([byteArray], { type: 'application/pdf;base64' });
            var fileURL = URL.createObjectURL(file);
            //window.open(fileURL);

            Object.assign(document.createElement('a'), { target: '_blank', href: fileURL}).click();
            document.querySelector('.modalLoaderErro').classList.add('off');
        }).catch(e=>{
          setErro("Erro ao obter PDF da proposta '"+proposta+"'");
        });
    };

    if(erroIntegracao){
        return <Redirect to={{
            pathname: '/error',
            search: '?erro=' + erroIntegracao
        }} />
    }else //PLV-4896 - FIM
        return(
        <>
            <div id="propostas">
                <section className='modalLoaderErro off'>  {/*PLV-4928 - INÍCIO/FIM  */}
                    <div className='content'>
                        <div className="loader"></div>
                    </div>
                </section>
                {/*PLV-4928 - INÍCIO*/}
                <div className="pagina on">
                    <LocalSearch fields={search} setSearch={setSearchParams} />
                    {
                        Object.keys(searchParams).length === 0 ? 
                        "" : 
                        <>
                            <DataGrid cols={colunas} search={searchParams} getData={getDataTable} qtditens={qtditens}/>
                            {/* PLV-4265 Adicionando frase e orientação ao corretor - Felipe Pedro
                                INICIO */}
                            <span className="warning">
                                * Este portal não permite a atualização / continuidade de uma proposta iniciada em outro momento.
                            </span>
                            {/* FIM */}
                        </>
                    }
                {/*PLV-4928 - FIM  */}
                </div>
            </div>
            {/* PLV-5436 - INÍCIO */}
            <Modal open={!!status} onClose={() => setStatus(null)}>
                {!!status && <Stepper status={status} subStatus={subStatus} />}
            </Modal>
            {/* PLV-5436 - FIM */}
            {/* MGRC-705 - INICIO */}
            {showModalDownloadError && <ModalPdfError title="Erro no download" onClose={() => setShowModalDownloadError(false)}>Não foi possível retornar o documento, por gentileza aguarde alguns minutos e tente novamente.</ModalPdfError>}
            {showSpinner && <Spinner />}
            {/* MGRC-705 - FIM */}
            {/* FNPVVEP-97 - INICIO/FIM */}
            {showLinkModal && (
              <ModalPdfError title="Reenviar Link" onClose={closeLinkModal}>
                {linkModalContent}
              </ModalPdfError>
            )}
            {/* FNPVVEP-97 - INICIO/FIM */}
        </>
    );
}