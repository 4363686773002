// FNPVVEP-24 - FIX02 - INICIO
export const MSGS = 
    {
        infoMsg: 'Gerando arquivo CSV para download. Aguarde!!!',
        sucessoMsg: 'Arquivo gerado com sucesso!!!',
        erroMsg: 'Erro na geração do arquivo',
        formInfoMsg: 'Seu relatório está sendo processado. Isso pode pode levar algum tempo, por favor, volte mais tarde',
        relSucessoMsg: 'Relatório gerado com sucesso!',
        requiredTokenMsg: '* Token não pode ser vazio', // FNPVVEP-86 - INICIO/FIM | FNPVVEP-86 - FIX01 - INICIO/FIM
        erroTokenMsg: '* Token inválido', // FNPVVEP-86 - INICIO/FIM | FNPVVEP-86 - FIX01 - INICIO/FIM
        recoverTokenMsg: 'Recuperando seu Token, aguarde...' // FNPVVEP-86 - INICIO/FIM | FNPVVEP-86 - FIX01 - INICIO/FIM
    };
// FNPVVEP-24 - FIX02 - FIM    