// PLV-4259
import React, { useState, useEffect } from 'react';
import crypto from 'crypto';

import Module from './components/Module';
import ModulePayments from './components/ModulePayments';

import './style.scss';

export default function Details(props){
    const [opened, setOpened] = useState(false);
    const [paymentData, setPaymentData] = useState({});

    const modules = props.fields;
    const data = props.data;

   
    useEffect(e=>{
        setOpened(props.show);
    }, [props.show, props.data, props.close]);
    // PLV-4343 - INICIO
    useEffect(e=>{
        setPaymentData(props.data2);
    },
        [props.fields2, props.data2]
    );
    // PLV-4343 - FIM
    const close = () => {
        // document.querySelector('body').classList.remove('overflow-h');
        props.close();
    }

    const render = () =>{
        return opened? 
        (
            <div id="modal-details" className={opened? 'opened' : ''}>
            
                <div className="container">
                    <h1>Detalhes {props.title}</h1>
                    <div className="info">
                    { 
                            Object.keys(modules).map(key=>{
                                let module = modules[key];
                                let dataModule = data? data.details[key.toString()]: null;
                                let chave = crypto.randomBytes(4).toString('HEX');
                                return(<Module title={module.label} key={chave} data={dataModule} fields={module.fields} />)
                            })
                    }
                        {/* // PLV-4343 - INICIO                         */}
                        <ModulePayments title='Pagamentos' data={ paymentData } fields={ props.fields2 } ></ModulePayments>
                        {/* // PLV-4343 - FIM */}
                    </div>
                    <div className="close" onClick={close} ></div>
                </div>

            </div>
        ): 
        (   
            <div id="modal-details">
            </div>
        )
    }

    return render()
}