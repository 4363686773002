// MGRC-705 - INICIO
import React from 'react';

import Overlay from '../Overlay';

const Modal = ({ title = '', children, onClose }) => {
  const style = {
    modal: {
      position: 'relative',
      background: '#fff',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
      minWidth: '300px',
      maxWidth: '550px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '10px',
    },
    title: {
      marginTop: 0,
      fontSize: '16px',
    },
    closeButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      background: 'none',
      fontSize: '18px',
      padding: '10px',
      border: 'none',
      color: '#aaa',
    },
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <Overlay onClick={handleOverlayClick}>
      <div style={style.modal}>
        <div style={style.header}>
          <h2 style={style.title}>{title}</h2>
          <button style={style.closeButton} onClick={onClose}>
            &times;
          </button>
        </div>
        <div>{children}</div>
      </div>
    </Overlay>
  );
};

export default Modal;
// MGRC-705 - FIM
