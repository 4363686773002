// FNPVVEP-24 - INICIO
import React, { useState, useEffect } from 'react';
import IconRenderer from '../IconRenderer'; // FNPVVEP-24 - FIX - INICIO/FIM

import './style.scss';

export default function Alert({
  type,
  msg,
  stateAlert,
  timeOutRef,
  time=10000,
}) {
    const [showAlert, setShowAlert] = useState(stateAlert);
    const [firstRender, setFirstRender] = useState(true);
    const handleShowAlert = () => {
        if (!firstRender){
            if(timeOutRef.current){
                setShowAlert(false);
                clearTimeout(timeOutRef.current);
            }
            setShowAlert(true);
            timeOutRef.current = setTimeout(() => setShowAlert(false), time);    
        } else {
            setFirstRender(false);
        }
    };

    const icon = () => {
        if(type === 'infoMsg') return 'FaInfoCircle';
        if(type === 'warningMsg') return 'FaExclamationTriangle';
        if(type === 'successMsg') return 'FaCheckCircle';
        if(type === 'errorMsg') return 'FaWindowClose';
    }
    
    useEffect(() => {
        handleShowAlert();
    }, [stateAlert]);
        
    return (
        <>
            {showAlert && (
                <div className={`alert ${type} ${showAlert ? 'fade-in' : 'fade-out'}`}>
                <IconRenderer iconName={icon()} size={15} />{msg}
                </div>
            )}
        </>
    );
}
// FNPVVEP-24 - FIM
