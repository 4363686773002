// PLV-5436 - INÍCIO
import React from 'react';

import './style.css';

const Modal = ({ children, open, onClose }) => {
  return open ? (
    <div id="myModal" className="modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={onClose}>
          &times;
        </span>
        {children}
      </div>
    </div>
  ) : null;
};

export default Modal;
// PLV-5436 - FIM
