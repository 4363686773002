import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Validation from './components/Validation';

import Pesquisa from './pages/Pesquisa';
import Conta from './pages/Conta';
import Contrato from './pages/Contrato';
import Relatorio from './pages/Relatorio'; // FNPVVEP-23 - INICIO/FIM
import Orcamento from './pages/Orcamento';
import Caso from './pages/Caso';
import Proposta from './pages/Proposta';
import Pendencia from './pages/Pendencia'; // II-33 - INICIO/FIM
import Contratacao from './pages/Contratacao';
import Erro from './pages/Erro';
import OrcamentosAlterar from './pages/OrcamentosAlterar';
// PLV-4534 Felipe Inicio
import NovoSeguro from './pages/NovoSeguro';
// PLV-4534 Felipe Fim

export default function App() {
    return (
        <>
            <Router>    
                <Validation />
                <Switch>
                    {/* PLV-4534 - Felipe - INICIO */}
                    <Route path="/contratacao">
                        <Contratacao />
                    </Route>
                    {/* PLV-4534 - Felipe - FIM */}
                    <Route path="/contas">
                        <Conta />
                    </Route>
                    <Route path="/contratos">
                        <Contrato />
                    </Route>
                    {/* FNPVVEP-23 - INICIO */}
                    <Route path="/relatorios">
                        <Relatorio />
                    </Route>
                    {/* FNPVVEP-23 - FIM */}
                    <Route path="/casos">
                        <Caso />
                    </Route>
                    <Route path="/propostas">
                        <Proposta />
                    </Route>
                    {/* II-33 - INICIO */}
                    <Route path="/pendencias">
                        <Pendencia />
                    </Route>
                    {/* II-33 - FIM */}
                    <Route path="/orcamentos">
                        <Orcamento />
                    </Route>
                    
                    <Route path="/orcamentoContratacao" render={(props) => <OrcamentosAlterar {...props} />} />
                    
                    {/* PLV 4534 - Felipe - INICIO */}
                    <Route path="/NovoSeguro">
                        <NovoSeguro />
                    </Route>
                    {/* PLV 4534 - Felipe - FIM */}
                    <Route path="/error">
                        <Erro />
                    </Route>
                    <Route path="/">
                        <Pesquisa />
                    </Route>
                </Switch>
            </Router>
        </>
    );
}