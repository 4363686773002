// FNPVVEP-23 - INICIO
import React from 'react';

import './style.scss';

export default function MultiValueCheckbox({
  label,
  name,
  items = [],
  onChange = () => {},
  selectedValues = [],
}) {
  return (
    <div className="checkbox-wrapper">
      <label htmlFor="type">{label}</label>
      {items.map((item) => {
        const isActive = selectedValues.includes(item);
        return (
          <label key={item} className={isActive ? 'active' : ''}>
            <input
              type="checkbox"
              name={name}
              value={item}
              onChange={onChange}
              checked={isActive}
            />{' '}
            {item}
          </label>
        );
      })}
    </div>
  );
}
// FNPVVEP-23 - FIM
