import React, { useState } from 'react'; //PLV-4928 - INÍCIO/FIM  
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';
import { useHistory } from 'react-router-dom'; 

import './style.scss';

import DataGrid from '../../components/DataGrid';
import LocalSearch from '../../components/LocalSearch'; //PLV-4928 - INÍCIO/FIM 
import { dataLimiteConvivencia, getFormattedDate } from '../../util/dataConvivencia'; // RVI-292 - INICIO/FIM

export default function Orcamento(){
    const history = useHistory ()

    const [searchParams, setSearchParams] = useState({}); //PLV-4928 - INÍCIO/FIM 
    const colunas = ['Meio de comercialização','Número do orçamento','Produto','Nome','Status','Capital básico','Prêmio total', 'Contratação']; //PLV-4928 - FIX - INICIO/FIM 
    const qtditens = 10;
    
    // PLV 4334 - Felipe - INICIO
    const actionChange = (urlData, btn) => {
        console.log('urlData', urlData);
        // ANNVI-33 - INICIO
        if(btn === "proposta") urlData.consultivo.portalAdm.tarefa = 2;
        /*if(btn === "proposta") {
            // II-124 - INICIO
            urlData.consultivo.portalAdm.tarefa = 2;
            urlData.consultivo.codigoOfertaConsultiva = urlData.consultivo.codigoOfertaConsultiva === 'VIDA_INDIVIDUAL' ? 'VIDA_INDIVIDUAL_VP' : urlData.consultivo.codigoOfertaConsultiva;
            // II-124 - FIM
        }
        // RVI-292 - INICIO
        else if (
               btn === 'alterar' &&
               urlData.consultivo.codigoOfertaConsultiva ===
                 'VIDA_INDIVIDUAL' &&
               dataLimiteConvivencia(getFormattedDate(new Date()))
             ) {
               urlData.consultivo.codigoOfertaConsultiva = 'VIDA_INDIVIDUAL_VP';
             }
        // RVI-292 - FIM */
        // ANNVI-33 - FIM
        urlData = JSON.stringify(urlData)

        history.push({
            pathname: '/orcamentoContratacao',
            state: {
                data: urlData
            }
        }) 
    }
    // PLV 4334 - Felipe - FINAL

    const getDataTable = () => {
        document.querySelector('.modalLoaderErro').classList.remove('off'); //PLV-4928 - INÍCIO/FIM 
        document.querySelector('.pagina').classList.remove('on'); //PLV-4928 - INÍCIO/FIM 

        // RVI-292 - INICIO
        const getCodigoOferta = (segmento) => {
            /* II-56 - INICIO - FIX */
            const lblAptlmk = 'APTLMK';
            const lblVida = 'VIDA_INDIVIDUAL';
            const codigoOferta = localStorage.codigoOferta;
            console.log('codigoOferta', codigoOferta);
            if (segmento === "Individual") {
                // ANNVI-33 - INICIO
                //return codigoOferta.toUpperCase() === lblAptlmk ? lblAptlmk : lblVida;
                return codigoOferta.toUpperCase() === lblAptlmk ? lblAptlmk : codigoOferta.toUpperCase();
                // ANNVI-33 - FIM
            }
            /* II-56 - FIM - FIX */            
            return "VIAGEM";
        }
        // RVI-292 - FIM

        return axios.get('/api/budget',{  
                params: {
                    canal: 'Corretor',
                    idCanal: localStorage.getItem('susepCorretor'),
                    codigoOferta: localStorage.getItem('codigoOferta'),  //II-124 - INICIO/FIM
                    ...searchParams // PLV-4928 - INÍCIO/FIM  
                } 
            }).then(async r =>{ 
                if(r.data && r.data.data){
                    let dados = r.data.data.map((l) =>{
                        // PLV 4334 - Felipe - INICIO 
                        const alterar = "alterar"
                        const proposta = "proposta"
                        // PLV 4334 - Felipe - FINAL
                        let numOrcamento = l.orcamento.split('-')[1] 
                        let dadosUrl = {
                            consultivo: {
                                codigoOfertaConsultiva: getCodigoOferta(l.segmento), // RVI-292 - INICIO/FM
                                canalDistribuicao: "COR",
                                identificadorRepresentante: localStorage.getItem('susepCorretor'),
                                meioComercializacao: "Portal",
                                entradaNegocio: "CO2",
                                // PLV 4334 - Felipe - INICIO
                                voltar: true,
                                portalAdm: {
                                    tarefa: 1,
                                    numOferta: numOrcamento
                                }
                            },
                            acao: "CONTRATAR"
                        }
                        
                        // PLV 4334 - Felipe - FINAL 

                        return [l.meioComercializacao,
                                l.orcamento,
                                l.produto, // PLV-4928 - FIX - INICIO  
                                l.nome, //PLV-4928 - FIX - FIM 
                                l.status,
                                new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(l.capital),                                
                                new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(l.premio),
                                // PLV 4334 - Felipe - INICIO
                                <span>
                                    {/* II-56 - INICIO */}    
                                    {l.status === "Disponível para contratação" && l.segmento === "Individual" ? 
                                        <button className="btn btnContratacao-item" onClick={() => actionChange(dadosUrl, alterar)}><FaEdit size={10}/> Alterar</button> : ""}
                                    {l.status === "Em criação" && l.segmento === "Individual" ? 
                                        <button className="btn btnContratacao-item" onClick={() => actionChange(dadosUrl, alterar)}><FaEdit size={10}/> Alterar</button> : ""}
                                    {/* RVI-292 - INICIO */}
                                    {/* ANNVI-33 - INICIO */}
                                    {l.status === "Disponível para contratação" && l.segmento === "Individual" /*&& dataLimiteConvivencia(l.dataCriacao)*/ ?
                                        <button className="btn btnContratacao-item" onClick={() => actionChange(dadosUrl, proposta)}><FaEdit size={10}/> Proposta</button> : ""}
                                    {/* ANNVI-33 - FIM */}
                                    {/* RVI-292 - FIM */}
                                    {/* II-56 - FIM */}
                                </span>
                                // PLV 4334 - Felipe - FINAL
                            ]; 
                    });
                    return dados;
                }
                return [];
            });
    };

    //PLV-4928 - INÍCIO 
    const search = {
        nome: {
            label: 'Nome',
            module: [
                { param: 'nomeSegurado', label: 'Nome', type: 'text' },
            ],
        },
        apolice: {
            label: 'Orçamento',
            module: [
                { param: 'numeroOrcamento', label: 'Orcamento', type: 'orcamento', placeholder:'AAA-999999999' } //PLV-4928-FIX3 - INICIO/FIM
            ]
        },
        data: {
            label: 'Data',
            module: [
                { param: 'dataProposta', label: 'Data', type: 'data', placeholder:'DD/MM/YYYY' }
            ]
        },
    }
    //PLV-4928 - FIM 

    return(
        <div id="orcamentos">
           <section className='modalLoaderErro off'>  {/*PLV-4928 - INÍCIO/FIM */}
                <div className='content'>
                    <div className="loader"></div>
                </div>
            </section>
             {/* PLV-4928 - INÍCIO  */}
             <div className="pagina on">
                <LocalSearch fields={search} setSearch={setSearchParams} />
                {
                    Object.keys(searchParams).length === 0 ? 
                    "" : 
                    <>
                        <DataGrid cols={colunas} getData={getDataTable} search={searchParams} qtditens={qtditens}/>
                        <span className="warning">* Este portal não permite a atualização/continuidade de um orçamento iniciado em outro momento. </span>
                    </>
                }
            </div>
            {/* PLV-4928 - FIM  */}
        </div>
    );
}