// PLV-4259 
import React from 'react';
import crypto from 'crypto';

import Info from '../Info';
import { PassThrough } from 'stream';

export default function Module(props){
    let chave = crypto.randomBytes(4).toString('HEX');

    const renderLabel = (field) =>{
        let chaveInfo = crypto.randomBytes(4).toString('HEX');
        return <Info key={chaveInfo} field={field} value={props.data[field.value !== null? typeof field.value == 'string'? field.value : field.value.list: null]}/>
    }
    //FNPVVEP-33 INICIO
    const renderPolicy = (field) =>{
        let chaveInfo = crypto.randomBytes(4).toString('HEX');
        if(field === 'Contrato'){
        return(
            <div className="subtitle" key={chaveInfo}>
                <ul>
                    <b>Número da apólice</b>
                    <li>
                        <p>{props.data.policy}</p>
                    </li>
                </ul>
            </div>
        )}
    }
    

    return (
        <div className="module" key={chave}>
            <h2>{props.title}
            {renderPolicy(props.title)}
            </h2>
            {
                props.fields.map(field => {
                    if(field.value !== 'policy'){
                        return renderLabel(field);
                    }
                })
            }
        </div>
    )
}
//FNPVVEP-33 FIM