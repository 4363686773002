import React, { useEffect, useState } from 'react';

import './style.scss';
import Logo from '../../assets/porto-seguro-logo-2.png';

export default function Erro(props){
    const [erroIntegracao, setErroIntegracao] = useState('');
    
    useEffect(e=>{
        const urlParams = new URLSearchParams(window.location.search);
        setErroIntegracao(urlParams.get('erro'))
    }, [])

    return(
        <main id="erro">
            <img src={Logo} alt="Porto seguro"/>
            <span>{erroIntegracao}</span>
        </main>
    );
}