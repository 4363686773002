import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { getCodigoOferta } from '../../util/codigoOferta'; // ANNVI-1 - INICIO/FIM // ANNVI-33 - INICIO/FIM

import Header from '../Header';

export default function Validation(){
    // const [valido, setValido] = useState(false);
    let valido = false;
    const [susep, setSusep] = useState('');
    const [validSession, setValidSession] = useState(true);
    const [erroIntegracao, setErroIntegracao] = useState('Erro de sistema');

    const urlParams = new URLSearchParams(window.location.search);
    
    useEffect(e=>{
        const setStorage = () => {
            let data = new Date();
            data.setHours(data.getHours() + 1);
            localStorage.setItem("susepCorretor", urlParams.get('corsus') || '');
            localStorage.setItem("codigoOferta", getCodigoOferta(urlParams.get('codigoOferta') || '')); // PLV-4314 // ANNVI-1 - INICIO/FIM // ANNVI-33 - INICIO/FIM
            localStorage.setItem("expires", data);
        }
    
        const getStorage = () => {
            if(localStorage.getItem('expires')){
                if(new Date(localStorage.getItem('expires')) < new Date()){
                    localStorage.clear();
                }
                return true;
            }
            return false;
        }

        // const validate = async () => {  
        //     if(
        //         urlParams.get('webusrcod') !== null &&
        //         urlParams.get('sesnum') !== null &&
        //         urlParams.get('corsus') !== null
        //     ){
        //         await axios.post('/auth', {
        //             codigoUsuario : urlParams.get('webusrcod'),
        //             numeroSessao  : urlParams.get('sesnum'),
        //             susepCorretor : urlParams.get('corsus')
        //         }).then(e=>{
        //             if(e.status === 200){
        //                 valido = true;
        //                 return true;
        //             }else{
        //                 setErroIntegracao(e.response.data.result.userErrorDescription || 'A sessão do usuário é inválida');
        //                 return false;
        //             }
        //         }).catch(e=>{
        //             setErroIntegracao(e.response.data.result.userErrorDescription || 'A sessão do usuário é inválida');
        //             return false;
        //         });
        //     }else{
        //         setErroIntegracao('Parâmetros de entrada necessários');
        //         return false;
        //     }
        // }

        const validate = async () => {
            if(
                urlParams.get('webusrcod') !== null &&
                urlParams.get('sesnum') !== null &&
                urlParams.get('corsus') !== null    
            ){
                valido = true;
                return true;
            }else{
                setErroIntegracao('Parâmetros de entrada necessários');
                return false;
            }
        }

        const valid = async () => {
            // ANNVI-1 - INICIO
            if(urlParams.get('erro')){
                setValidSession(false);
                return;
            }
            // ANNVI-1 - FIM
            if(!getStorage()){
                await validate();
                if(valido){
                    setStorage();
                    setValidSession(true);
                }else{
                    setValidSession(false);
                }
            }else{
                //PLV-4782 - INICIO
                if(urlParams.get('codigoOferta')) 
                    localStorage.setItem("codigoOferta", getCodigoOferta(urlParams.get('codigoOferta') || '')); // ANNVI-1 - INICIO/FIM // ANNVI-33 - INICIO/FIM
                 //PLV-4782 - FIM
                //PLV-5079 - INICIO
                if(urlParams.get('corsus')) 
                    localStorage.setItem("susepCorretor", urlParams.get('corsus') || ''); 
                //PLV-5079 - FIM
                setValidSession(true);
            }
        }

        const getValid = async()=>{
            await valid();
        }
        
        setSusep(localStorage.getItem('susepCorretor') || urlParams.get('corsus') || '');
        getValid();
    }, [susep, urlParams, valido, validSession]);

    return validSession? 
            <Header susep={susep} /> : 
            <Redirect to={{
                pathname: "/error",
                search: "?erro=" + erroIntegracao,
            }} />; 
}