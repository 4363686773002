//II-33 - INICIO
import React, { useState } from 'react'; 
import axios from 'axios';
import DataGrid from '../../components/DataGrid';
import Search from './Search';
import '../Proposta/style.scss';
import { FaWrench } from 'react-icons/fa'; // FNPVVEP-102 - INICIO/FIM
// FNPVVEP-134 - INICIO
import Modal from '../../components/Modal';
import Spinner from '../../components/Spinner';
import "./style.scss";
// FNPVVEP-134 - FIM

export default function Pendencia(){
  const colunas = ['Proposta', 'Motivo da Pendência', 'Regularização de pendência'];
  const qtditens = 10;    
  const [searchParams, setSearchParams] = useState({}); 
  // FNPVVEP-134 - INICIO
  const [showSpinner, setShowSpinner] = useState(false);
  const [modalMessage, setModalMessage] = useState({ title: null, body: null });

  const handleModalClose = () => {
    setModalMessage({ title: null, body: null });
  };

  const showModalError = () => {
    setModalMessage({
      title: 'Pendência não lozalizada',
      body: (
        <>
          <p>Por favor entre em contato com a Central de Atendimento:</p>
          <ul className="contact">
            <li>11 3366 3377 (Grande São Paulo)</li>
            <li>0800 727 9393 (Demais localidades)</li>
            <li>
              WhatsApp:{' '}
              <a
                href="https://wa.me/551130039303?text=Olá, estou com problemas na regularização da pendência da minha proposta!"
                target="_blank"
              >
                (11) 3003 9303
              </a>
            </li>
          </ul>
        </>
      ),
    });
  };

  // FNPVVEP-102 - INICIO
  const handleRegularizar = async (proposta, inicioVigencia) => { // FNPVVEP-134 - INICIO/FIM - FIX-01
    try {
      setShowSpinner(true);
      const response = await axios.post('/api/regularizar-pendencias', {
        proposta,
        inicioVigencia, // FNPVVEP-134 - INICIO/FIM - FIX-01
      });
      const { data } = response;

      const { urlDocumentoPendencia } = data;
      if (urlDocumentoPendencia) {
        window.open(urlDocumentoPendencia, '_blank');
        handleModalClose();
      } else {
        showModalError();
      }
    } catch (error) {
      console.error(error);
      showModalError();
    } finally {
      setShowSpinner(false);
    }
  };
  // FNPVVEP-102 - FIM
  // FNPVVEP-134 - FIM

  const getDataTable = () => {
      document.querySelector('.modalLoaderErro').classList.remove('off'); 
      document.querySelector('.pagina').classList.remove('on');          
      return axios.get('/api/proposal', { 
          params: {
              canal: 'Corretor',
              idCanal: localStorage.getItem('susepCorretor'),
              codigoOferta: localStorage.getItem('codigoOferta'),  //II-124 - INICIO/FIM
              pendencia: true,
              ...searchParams
          }
          }).then(r=>{
            console.log('proposal', r);
            if(r.data && r.data.data) {
              return axios.get('/api/pendency', {
                params: {
                    proposta: r.data.data,
                } 
               
              }).then(e => {
                const dados = e.data.data.map((l) => {
                    return [
                      l.proposalName,
                      l.pendency,
                      // FNPVVEP-134 - INICIO
                      // FNPVVEP-102 - INICIO
                      // FNPVVEP-134 - INICIO - FIX-01
                      <button className="btn hidden" onClick={() => handleRegularizar(l.proposalName, l.inicioVigencia)}> {/* FNPVVEP-134 - INICIO/FIM */}
                        <FaWrench size={10} /> Regularizar pendência
                      </button>
                      // FNPVVEP-134 - FIM - FIX-01
                      // FNPVVEP-102 - FIM
                      // FNPVVEP-134 - FIM
                    ];                                
                });
                return dados;                
              }).catch(e=>{
                console.error(e);
              });        
            }
            return [];
          });
  };

  return(
    <>
        <div id="propostas">
            <section className='modalLoaderErro off'>
                <div className='content'>
                    <div className="loader"></div>
                </div>
            </section>
            <div className="pagina on pendencia">
                <Search setSearch={setSearchParams} />
                {
                    Object.keys(searchParams).length === 0 ? 
                    "" : 
                    <>
                        <DataGrid cols={colunas} search={searchParams} getData={getDataTable} qtditens={qtditens}/>
                    </>
                }
            </div>
        </div>
        {/* FNPVVEP-134 - INICIO */}
        {modalMessage.body !== null && (
          <Modal title={modalMessage.title} onClose={handleModalClose}>
            {modalMessage.body}
          </Modal>
        )}
        {showSpinner && <Spinner />}
        {/* FNPVVEP-134 - FIM */}
    </>
  );
}
//II-33 - FIM