// PLV-4254 
import React from 'react';

export default function Select(props){
    return (
        <div className="select-wrapper" id={`div-${props.param}`} key={props.id} error={props.error}> 
            <select id={props.id} defaultValue={'null'} name={props.param} >
                {/* PLV 4351 - Felipe -INICIO */}
                <option value='null' disabled={true}>Selecione</option>
                {/* PLV 4351 - Felipe -FINAL */}
                {props.opcoes.map(opcao=>{
                    let chave = props.param + "_" + opcao.id;
                    return <option value={opcao.id} id={opcao.id} key={chave}>{opcao.label}</option>
                })}
            </select>
            <label htmlFor={props.id}>{props.nome}</label>
        </div>
    );
}