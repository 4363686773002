// MGRC-649 - MGRC-705 - INICIO
import axios from 'axios';

export const openPdfInNewTab = (base64string) => {
  const newTab = window.open();
  const byteCharacters = atob(base64string);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });

  const dataUrl = URL.createObjectURL(blob);

  const htmlContent = `<!DOCTYPE html><html><head><style>body{margin:0;padding:0;overflow-x:hidden;height:100vh;display:flex;align-items:center;justify-content:center;}iframe{width:100vw;height:100vh;border:none;z-index:2}#loading{position:absolute;display:inline-block;width:50px;height:50px;border:3px solid rgba(0,0,0,.3);border-radius:50%;border-top-color:#000;animation:spin 1s ease-in-out infinite;-webkit-animation:spin 1s ease-in-out infinite;z-index:1}@keyframes spin{to{-webkit-transform: rotate(360deg);}}@-webkit-keyframes spin{to{-webkit-transform:rotate(360deg);}}</style></head><body><iframe src="${dataUrl}"></iframe></body></html>`;

  newTab.document.write(htmlContent);
  newTab.document.close();
};

const getDocument = async (token) => {
  try {
    const response = await axios.get('/api/document', {
      params: {
        token,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Erro ao fazer o download do documento');
    }
  } catch (error) {
    console.error(error);
  }
};

export default getDocument;
// MGRC-649 - MGRC-705 - FIM
