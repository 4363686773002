import React from 'react';

import './style.scss';
import Logo from '../../assets/porto-seguro-logo-2.png';

// import GlobalSearch from '../../components/GlobalSearch';

export default function Entrada(){
    return(
        <div>
            <main>
                <img src={Logo} alt="Porto Seguro"/>
                {/* <GlobalSearch /> */}
            </main>
        </div>
    );
}