// MGRC-705 - INICIO
import React from 'react';

const Overlay = ({ children, onClick = () => {} }) => {
  const style = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1, // FNPVVEP-24 - FIX - INICIO/FIM
    },
  };

  return (
    <div style={style.overlay} onClick={onClick}>
      {children}
    </div>
  );
};

export default Overlay;
// MGRC-705 - FIM
