const functions = {
    //PLV-5817 - IN�CIO
    parseDate: (datetime, showMinute = false) => { 
        const isValidDate = (dateObject) =>
          new Date(dateObject).toString() !== 'Invalid Date';
        const formattedDate = new Date(datetime);
        //PLV-5817 - IN�CIO
        const date =
          isValidDate(formattedDate) && !showMinute
            ? formattedDate.toLocaleString('pt-BR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
            : formattedDate.toLocaleString('pt-BR', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            }).replace(', ', ' - '); //FNPVVEP-24 - INICIO/FIM
            ;
        return date;
      },
    //PLV-5817 - FIM
    // FNPVVEP-24 - INICIO
    removeAccents: (str) => {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
    // FNPVVEP-24 - FIM
};

export default functions;