// FNPVVEP-23 - INICIO
import React from 'react';
import IconRenderer from '../../components/IconRenderer'; //FNPVVEP-24 - FIX - INICIO/FIM

export default function Button({ onClick, name, icon }) { // FNPVVEP-24 - FIX06 - INICIO/FIM
  return (
    <button className="new-report-btn" onClick={onClick}>
      <IconRenderer iconName={icon} size={10} /> {name} {/*FNPVVEP-24 - FIX06 - INICIO/FIM*/}
    </button>
  );
}
// FNPVVEP-23 - FIM
