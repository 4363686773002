// PLV-4343 
import React from 'react';
import crypto from 'crypto';
import VMasker from '../../../../util/vMasker'; //FNPVVEP-33-FIX-2 INICIO-FIM

export default function ModulePayments(props){

    let chave = crypto.randomBytes(4).toString('HEX');

    //FNPVVEP-33 INICIO
    let data = props.data.details.financial.parcel;
    //FNPVVEP-33 FIM

    const render = () =>{
        return (
            
            <div className="module" key={chave}>
                <div className="info list">
                    <b>Parcelas</b>
                    {/* <ul>
                        <li className="list-item">Parcela número<br/>0</li>
                        <li className="list-item">Valor da parcela<br/>0.00</li>
                        <li className="list-item">Situação</li>
                        <li className="list-item">Data de pagamento</li>
                        <li className="list-item">Data de vencimento</li> 
                    </ul> */}
                {/*FNPVVEP-33 INICIO*/}
                <table>
                    <thead>
                    <tr key={chave}>
                        <td><b>Valor da parcela</b></td>
                        <td><b>Parcela número</b></td>
                        <td><b>Situação</b></td>
                        <td><b>Data de vencimento</b></td>
                    </tr>
                    </thead>
                    <tbody>
                {
                    
                    Object.entries(data).map(([key, value]) => {
                        //mspainconsole.log(value['statusQuote']);
                        chave = crypto.randomBytes(4).toString('HEX');
                        let parcela = VMasker.toMoney(parseFloat(value['valorpremiomensal']).toFixed(2)) //FNPVVEP-33-FIX-2 INICIO-FIM

                        return(<tr key={chave}>
                        <td id={chave.toString()+'_0'} key={key.toString()+'_0'} className='list-item'>R$ {parcela}</td>{/*FNPVVEP-33-FIX-2 INICIO-FIM*/}
                        <td id={chave.toString()+'_1'} key={key.toString()+'_1'} className='list-item'>{value['numeroparcela']}</td>
                        <td id={chave.toString()+'_2'} key={key.toString()+'_2'} className='list-item'>{value['status']}</td>
                        <td id={chave.toString()+'_3'} key={key.toString()+'_3'} className='list-item'>{value['datavencimento']}</td>
                        </tr>); 
                    })                    
                }
                </tbody>
                </table>
                {/*FNPVVEP-33 FIM*/}
                    <div id="parcelas">
                    </div>
                </div>

            </div>
            
        )
    }

    return render()
}