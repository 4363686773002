// PLV-5436 - INÍCIO
import React, { useEffect, useState } from 'react';

import { FaCaretDown, FaCaretUp, FaSpinner } from 'react-icons/fa'; //PLV-5817 - INÍCIO/FIM
import axios from 'axios'; //PLV-5817 - INÍCIO/FIM
import qs from 'qs'; //PLV-5817 - INÍCIO/FIM
import fnc from '../../../util/functions'; //PLV-5817 - INÍCIO/FIM

import './style.scss';

const Stepper = ({ status, subStatus }) => {
  //PLV-5817 - INÍCIO
  const lastIndexStatus = 3;  
  let expandStatus = true;
  let expandRecusado = false;

  if(status[lastIndexStatus].date){
    expandRecusado = true;
    expandStatus = false;
  }
  //PLV-5817 - FIM

  const [showSubstatus, setShowSubstatus] = React.useState(expandStatus); //PLV-5817 - INÍCIO/FIM
  const [showRecusado, setShowRecusado] = React.useState(expandRecusado); //PLV-5817 - INÍCIO/FIM
  const [arrSubStatus, setArrSubsStatus] = useState([]); //PLV-5817 - INÍCIO/FIM
  const [arrRecusado, setArrRecusado] = useState([]); //PLV-5817 - INÍCIO/FIM
  //PLV-5817 - INÍCIO
  useEffect(() => {
    (async () => {
      try{
        await axios.get('/api/status', {
          params: {
            recusas: [{"codigo": subStatus.motivoRecusa === null ? 0 : subStatus.motivoRecusa}], //II-49 - INICIO/FIM
            criticas: subStatus.copiedSortedObjectsNoDate
          },
          paramsSerializer: params => {
            return qs.stringify(params)
          },
        }).then(s=>{
          const arrSub = [];
          const arrRec = [];
          const arrRecBkp = []; //II-49 - INICIO/FIM
          let tempArr = [];
          let obj = {};
          const indexZero = 0;
          const statusRecusado = 'Recusado';
          const statusPendente = 'Pendente';
          const statusToCheck = ['Análise interna', 'Resseguro'];

          const sortArrFunc = (arr) => {
            return arr.sort((a, b) => a.prioridade - b.prioridade);
          }

          //II-49 - INICIO
          if(!s.data.data.data.userErrorDescription){
            const indexRecusada = 0;
            const emptyArr = 0;
            const numberOfElementsToRemove = 1;
            const recusada = s.data.data.data[indexRecusada];
            if(recusada.texto){
              arrRec[indexZero] = {texto: recusada.texto, fr: true, data: status[lastIndexStatus].date};              
            }else{
              arrRecBkp[indexZero] = {texto: recusada.descricao, fr: true, data: status[lastIndexStatus].date};
            }
            s.data.data.data.splice(indexRecusada, numberOfElementsToRemove);
            s.data.data.data.forEach((data, index) => {
              if(data.texto){
                obj = Object.assign(data, {grupo: subStatus.sortedObjects[index].grupo, status: subStatus.sortedObjects[index].status, data: subStatus.sortedObjects[index].data});
                if(subStatus.sortedObjects[index].status === statusRecusado && arrRec.length === emptyArr){
                  arrRec[indexZero] = obj;
                }else{
                  console.info('obj', obj);
                  if(statusToCheck.includes(obj.grupo) && obj.status === statusPendente){
                    tempArr.push(obj);
                  }else{
                    if(tempArr.length){
                      arrSub.push(...sortArrFunc(tempArr));
                      arrSub.push(obj);
                      tempArr = [];
                    }else{
                      arrSub.push(obj);                    
                    }
                  }  
                }
              }  
            })
            if(arrRec.length === emptyArr) arrRec.push(...arrRecBkp);
            if(tempArr.length) arrSub.push(...sortArrFunc(tempArr));  
          }
          console.info('arrSub', arrSub);
          console. info('arrRec', arrRec);
          setArrSubsStatus(arrSub);
          setArrRecusado(arrRec)
        })
      }catch(error){
        console.error(error);
      }
    })();
  }, []);
  //PLV-5817 - FIM

  return !!status ? (
    <div className="wrapper option-1 option-1-1">
      <ol className="c-stepper">
        {status.map(({ label, date, icon }) => {
          const emAnalise = label === 'Em análise de emissão';
          const recusado = label === 'Proposta recusada'; //PLV-5817 - INÍCIO/FIM
          const sizeIcon = recusado ? 32 : 30; //PLV-5817 - INÍCIO/FIM
          const opacity = !!date ? 1 : 0.5;
          const display = label === 'Disponível para contratação' ? 'none' : 'flex'; //PLV-5817 - INÍCIO/FIM
          const isActive = () => {
            const index = status.findIndex((s) => s.label === label);
            return !!status[index + 1]?.date;
          };

          return (
            <li
              className={`c-stepper__item ${isActive() ? 'active' : ''}`}
              key={label}
              style={{ opacity,display }} //PLV-5817 - INÍCIO/FIM
            >
              <div className="icon">
                {React.createElement(icon, { size: sizeIcon })} {/*PLV-5817 - INÍCIO/FIM*/}
              </div>
              <h3 className="c-stepper__title">{label}</h3>
              <p className="c-stepper__desc">{date}</p>
              {/*PLV-5817 - INÍCIO*/}
              {emAnalise && arrSubStatus.length <= 0 && (
                <div><FaSpinner className='spinner' size={30} /></div>
              )}
              {/*PLV-5817 - FIM*/}
              {emAnalise && arrSubStatus.length > 0 && ( //PLV-5817 - INÍCIO/FIM
                <span
                  className="show-details"
                  onClick={() => setShowSubstatus((v) => !v)}
                >
                  Ver detalhes {showSubstatus ? <FaCaretUp size={15} /> : <FaCaretDown size={15} />}
                </span>
              )}
              {emAnalise && arrSubStatus.length > 0 && showSubstatus && ( //PLV-5817 - INÍCIO/FIM
                <ol
                  className={`c-stepper c-stepper-sub ${
                    showSubstatus ? '' : 'hidden'
                  }`}
                >
                  {arrSubStatus && arrSubStatus.map((data, index) => (
                  <li className="c-stepper__item item-sub" key={index}>
                    <div className="vertical-line" /> {/*PLV-5817 - INÍCIO/FIM*/}
                    <div className="icon">
                      {React.createElement(subStatus.icon, { size: 30 })}
                    </div>
                    <h3 className="c-stepper__title">{data.texto}</h3>
                    <p className="c-stepper__desc">{fnc.parseDate(data.data)}</p>
                  </li>                  
                  ))}
                </ol>
              )}
              {/*PLV-5817 - INÍCIO*/}
              {recusado && arrRecusado.length <= 0 && (
                <div><FaSpinner className='spinner' size={30} /></div>
              )}              
              {recusado && arrRecusado.length > 0 && (
                <span
                  className="show-details"
                  onClick={() => setShowRecusado((v) => !v)}
                >
                  Ver detalhes {showRecusado ? <FaCaretUp size={15} /> : <FaCaretDown size={15} />}
                </span>
              )}
              {recusado && arrSubStatus.length > 0 && showRecusado && (
                <ol
                  className={`c-stepper c-stepper-sub ${
                    showRecusado ? '' : 'hidden'
                  }`}
                >
                  {arrRecusado && arrRecusado.map((data, index) => (
                  <li className="c-stepper__item item-sub" key={index}>
                    <div className="vertical-line" />
                    <div className="icon">
                      {React.createElement(subStatus.icon, { size: 30 })}
                    </div>
                    <h3 className="c-stepper__title">{data.texto}</h3>
                    <p className="c-stepper__desc">{data.fr ? data.data : fnc.parseDate(data.data)}</p> {/*II-49 - INICIO/FIM */}
                  </li>                  
                  ))}
                </ol>
              )}
              {/*PLV-5817 - FIM*/}
            </li>
          );
        })}
      </ol>
    </div>
  ) : null;
};

export default Stepper;
// PLV-5436 - FIM
