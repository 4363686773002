import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom'; // PLV-5441 - INICIO/FIM
import { getCodigoOferta } from '../../util/codigoOferta'; // ANNVI-1 - INICIO/FIM
// PLV-4534 - Felipe - INICIO
//import { useParams } from 'react-router-dom'
// PLV-4534 - Felipe - FIM

import './style.scss';

// ANNVI-1 - INICIO
export default function Contratacao(){
    const [urlPortal, setUrlPortal] = useState('');
    // PLV-5441 - INICIO
    const search = useLocation().search;
    const proposta = new URLSearchParams(search).get('proposta');
    const budget = new URLSearchParams(search).get('budget');
    // PLV-5441 - FIM
    const fadeIframe = ()=>{
        document.querySelector('iframe').classList.add('on');
        document.querySelector('.modalLoaderErro').classList.add('off'); //II-114 - INICIO/FIM
    }    

    useEffect(() => {
        (async () => {
            try{
                const codOferta = localStorage.getItem('codigoOferta') ? localStorage.getItem('codigoOferta').toUpperCase() : getCodigoOferta(new URLSearchParams(search).get('codigoOferta')).toUpperCase;
                const portal = await axios.get(`/api/geturlportal/${codOferta}`)
                const whatUrl = window.location.href.indexOf("localhost") >= 0 && window.location.href.indexOf("vida_individual") >= 0 ? 'https://portoseguro--sniper.sandbox.my.salesforce-sites.com/OrcamentoVidaIndividual/OrcamentoVidaIndividual' : portal.data.data

                if (codOferta === "ESPECIFICO-VIAGEM") codOferta = 'VIAGEM' 
                const dados = {
                    consultivo: {
                        codigoOfertaConsultiva: codOferta,
                        canalDistribuicao: "COR",
                        identificadorRepresentante: localStorage.getItem('susepCorretor'),
                        meioComercializacao: "Portal",
                        entradaNegocio: "CO2",
                        voltar: false
                    },
                    acao: "CONTRATAR"
                }
        
                if (!!proposta) Object.assign(dados.consultivo, { nProposta: proposta });
        
                if(!!budget){
                    const fields = await axios.post('/api/autocompletebudget', { id: budget });
                    if(fields.status === 200){
                        Object.assign(dados.consultivo, { fieldsBudget: btoa(JSON.stringify(fields.data.data)) });
                    }    
                }

                setUrlPortal(`${whatUrl}?dados=${JSON.stringify(dados)}`);
            }catch(error){
                console.error(error);
            }
        })();
    }, [urlPortal]);

    return(
        <div id="contratacao">
            <section className='modalLoaderErro'>
                <div className='content'>
                    <div className="loader"></div>
                </div>
            </section>
           <iframe src={urlPortal} frameBorder="0" onLoad={fadeIframe} title="Contratacao"></iframe>
        </div>
    );
}
// ANNVI-1 - FIM