// MGRC-705 - INICIO
import React from 'react';

import Overlay from '../Overlay';

const Spinner = () => {
  const spinnerStyle = {
    border: '4px solid rgba(255, 255, 255, 0.3)',
    borderRadius: '50%',
    borderTop: '4px solid #3498db',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
  };

  return (
    <Overlay>
      <div style={spinnerStyle}></div>
    </Overlay>
  );
};

export default Spinner;
// MGRC-705 - FIM
